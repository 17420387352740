import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import { H1, Grid } from "@actionishope/shelley";
import Banner from "../components/Banner/Banner";
import SocialShare from "../components/SocialSahre/SocialShare";
import { classes as spacing } from "@actionishope/shelley/styles/default/spacing.st.css";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";
import { classes as text } from "../styles/puma/text.st.css";
import "../styles/puma/privacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={spacing.mb8}>
      <Helmet>
          <title>{'Privacy Policy - Solutions database'}</title>
          <meta name="description" content="Privacy Policy Description"/>
          <meta property="og:title" content="Privacy Policy"/>
          <meta property="og:description" content="Privacy Policy Description"/>
          <meta property="og:image" content="https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg"/>
          <meta property="og:type" content="website"/>
      </Helmet>
      <Banner
        className={grid.edge}
        media={
          <div
            style={{
              opacity: 0.15,
              backgroundImage: `url(https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg)`,
              backgroundSize: "cover",
              backgroundPosition: "0 50%",
            }}
          />
        }
      >
       <H1 vol={8} uppercase>
          <span className={classnames(text.textBannerInline, text.textBg2)}>
            Privacy Policy
          </span>
        </H1>
        <SocialShare title={'Privacy Policy'} url={document.URL} description={'Privacy Policy Description'}></SocialShare>
      </Banner>
      <Grid tag="main" variant={1} formatted>
      <div className={classnames(grid.goal, 'policyContainer')}>
        <h1>PRIVACY POLICY FOR VOCALEYES</h1>
        <p>On May 25th 2018 new regulations come into force throughout the EU to protect your rights and harmonise data protection laws. Your privacy is important to VocalEyes and central to the way we develop trust with our clients. We have developed this Privacy Policy to provide you with information on how we process your personal data when you use the VocalEyes website, the VocalEyes Mobile app and other online service that is powered through the VocalEyes platform.</p>
        <p>“Personal data” is information relating to an identified or identifiable natural person.</p>
        <h2>CATEGORIES OF PERSONAL DATA PROCESSED BY VOCALEYES</h2>
        <p>When you create a VocalEyes account, we ask you to provide your email address, name and postcode. You can choose to provide only your first name or a nickname instead of your full name if you wish.</p>
        <p>Purposes and Legal Grounds:</p>
        <p>(a) VocalEyes processes your email address because you may use your email address and your password to sign in to your account. The legal ground for processing your email address for this purpose is based on VocalEyes’ legitimate interest in protecting the security of your account.</p>
        <p>(b) VocalEyes also processes your email address for the purpose of sending you important information about engagement and activity occurring within your group. We may also send you notifications to inform you of important safety information or material changes to this Privacy Policy. The legal ground for processing your email address and name for these purposes is VocalEyes’ legitimate interest in providing you with updates to your group or important changes to this Privacy Policy.</p>
        <p>(c) If you provide your opt-in consent to receive marketing information from VocalEyes, VocalEyes will also process your email address for the purpose of sending you marketing information about our products and services. The legal ground for processing your email address for this purpose is your consent. You may withdraw your consent at any time by changing your preferences in your VocalEyes account or through the unsubscribe link at the bottom of our marketing emails.</p>
        <p>(d) VocalEyes also processes your email address associated your VocalEyes account when you interact with our customer support representatives. The legal ground for this processing is our legitimate interest in providing quality customer support.</p>
        <p>(e) VocalEyes uses your postcode enable membership of your local ward and district level community groups. The legal ground for this processing is our legitimate interest in providing a community engagement platform as a service.</p>
        <h1>CATEGORIES OF RECIPIENTS OF PERSONAL DATA</h1>
        <h2>FELLOW MEMBERS OF A VOCALEYES PRIVATE GROUP</h2>
        <p>The content you contribute to your member approved or private groups are set to “Anonymous” by default. A group administrator may decide to allow members of your group to see the author of material and this will show your name alongside your comments and ideas which you publish. Non members of your group will never see your name or your email address.  Public groups like ward and district level groups are always anonymous. When you interact with other users through the messaging module, you will be displaying your name as the sender of the message to the recipient even if your settings are set to “Private”.</p>
        <h2>OTHER DISCLOSURES</h2>
        <p>We may disclose personal data about you to others: (a) if we have your valid consent to do so; (b) to comply with a valid court order, legal process, or other legal obligation; (c) to enforce any of our terms and conditions or policies; or (d) as necessary to pursue available legal remedies or defend legal claims.</p>
        <p>We may also transfer your personal data to an affiliate, a subsidiary or a third party in the event of any reorganisation, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of VocalEyes’ business, assets or stock, including, without limitation, in connection with any bankruptcy or similar proceeding, provided that any such entity that we transfer personal data to will not be permitted to process your personal data other than as described in this Privacy Policy without providing you notice and, if required by applicable laws, obtaining your consent.</p>
        <h1>COOKIES AND SIMILAR TECHNOLOGIES</h1>
        <h3>Website</h3>
        <p>To help analyze how you and other visitors navigate the VocalEyes website, compile aggregate statistics about site usage and response rates, help diagnose any problems with VocalEyes servers, we, with assistance from third-party analytics service providers, collect certain information when you visit our site. This information includes IP address, geographic location of the device, browser type, browser language, date and time of your request, time(s) of your visit(s), page views and page elements (e.g., links) that you click. We may use cookies, pixel tags, web beacons, clear GIFs or other similar tools on our site or in our email messages to assist us in collecting and analysing such information. We use this information to provide better, more relevant content on our site, to identify and fix problems, and to improve your overall experience on our site.</p>
        <p>If you do not want information collected through the use of these technologies, there is a simple procedure in most browsers that allows you to automatically decline many of these technologies.</p>
        <p>If you reside in the European Union or other jurisdiction that requires us to obtain your consent to use cookies on our sites, you will have an opportunity to manage your cookie preferences on our sites; except that certain cookies are required to enable core site functionality, and you cannot choose to disable those cookies.</p>
        <br/>
        <h3>Mobile app</h3>
        <p>We also collect data from users about their usage of the VocalEyes Mobile app. The types of analytical information that are collected include the date and time the app accesses our servers, app version, the location of the device, language setting, what information and files have been downloaded to the app, user behaviour (e.g., features used, frequency of use), device state information, device model, hardware and operating system information, and information relating to how the app functions. VocalEyes uses this data to improve the quality and functionality of the VocalEyes Mobile app; to develop and market products and features that best serve you and other users; and to help identify and fix app stability issues and other usability problems as quickly as possible.</p>
        <p>The legal ground for processing this analytical information is our legitimate interest in understanding how our users interact with our app and website so we can enhance the user experience and functionality of our app and website.</p>
        <p>The third-party providers of analytics and similar services we currently use:</p>
        <br/>
        <h3>Google Analytics</h3>
        <p>Google Analytics is used to track site statistics and user demographics, interests and behaviour on websites. We also use Google Search Console to help understand how our website visitors find our website and to improve our search engine optimisation.</p>
        <p><a href="https://tools.google.com/dlpage/gaoptout">Find out more information about how this analytics information may be used, how to control the use of your information, and how to opt-out of having your data used by Google Analytics.</a></p>
        <h1>PRIVACY POLICY UPDATES</h1>
        <p>We may update this Privacy Policy from time to time as we add new products, features and apps, as we improve our current offerings and as technologies and laws change. You can determine when this Privacy Policy was last revised by referring to the “Last updated” legend at the top of this page. Any changes will become effective upon our posting of the revised Privacy Policy.</p>
        <p>We will provide notice to you if these changes are material and, where required by applicable law, we will obtain your consent. This notice will be provided by email or by posting notice of the changes on the VocalEyes website and app that link to this Privacy Policy.</p>
        <h1>RETENTION OF PERSONAL DATA</h1>
        <p>We will retain your personal data as long as your VocalEyes account is considered to be active. In addition, see below under “Your Rights” for a description of your right of erasure.</p>
        <h1>DATA CONTROLLER</h1>
        <p>Your personal data collected by the VocalEyes community engagement platform  is controlled by VocalEyes Digital Democracy Ltd. which is located at The Environment Centre, Pier Street, Swansea. SA1 1RY.  Any queries relating to data protection should be emailed to privacy@vocaleyes.org.</p>
        <h1>YOUR RIGHTS</h1>
        <p>If you reside in the European Union, you have the right under the General Data Protection Regulation to request from VocalEyes  access to and rectification or erasure of your personal data, data portability, restriction of processing of your personal data, the right to object to processing of your personal data, and the right to lodge a complaint with a supervisory authority. If you reside outside of the European Union, you may have similar rights under your local laws.</p>
        <p>To request access to or rectification, portability or erasure of your personal data, or to delete your VocalEyes account, visit the Account Management Dashboard.</p>
        <p>If you live in the European Union and you wish to exercise your right to restriction of processing or your right to object to processing, contact VocalEyes’ Data Protection Officer at The Environment Centre, Pier Street, Swansea. SA1 1RY or by email at privacy@vocaleyes.org. If you do not live in the European Union but you believe you have a right to restriction of processing or a right to object to processing under your local laws, you may still contact VocalEyes at our  postal address or via email.</p>
        <h1>ICO REGISTRATION</h1>
        <p>We are registered with the Information Commissioner’s Office, the body that oversees data protection in the UK. By signing up to their data protection policies we are legally bound to uphold data protection.</p>

      </div>
      </Grid>
      {/* <Grid tag="main" variant={3} formatted className={classnames(spacing.mt3, grid.goal, 'policyContainer')}>
        <h1>PRIVACY POLICY FOR VOCALEYES</h1>
      </Grid> */}
    </div>

  );
};

export default PrivacyPolicy;
