import React from "react";
import classnames from "classnames";
import { classes } from "./social.share.st.css";

import { 
  FacebookShareButton, 
  FacebookIcon, 
  TwitterShareButton, 
  TwitterIcon, 
  LinkedinShareButton, 
  LinkedinIcon, 
  WhatsappShareButton, 
  WhatsappIcon, 
  EmailShareButton, 
  EmailIcon
} from "react-share";
import { Button } from "@actionishope/shelley";

const SocialShare = ({
  title='',
  url = '',
  description= ''
})=>{
  const [showResults, setShowResults] = React.useState(true)
  const onClick = () => setShowResults(showResults?false:true)
  return (
      <div className={classnames(classes.floating_menu)}>
        {
          (showResults) ? <div><FacebookShareButton url={url} hashtag={''} quote={title.concat(' - ').concat(description)}>
            <span title="Share on Facebook">
          <FacebookIcon round size={40}/></span>
        </FacebookShareButton><br />
        <TwitterShareButton
          url={url}
          title={title}>
          <span title="Share on Twitter">
          <TwitterIcon size={40} round /></span>
        </TwitterShareButton><br />
        <LinkedinShareButton url={url} title={title}>
        <span title="Share on Linkedin">
          <LinkedinIcon size={40} round/></span>
        </LinkedinShareButton><br />
        <WhatsappShareButton url={url} title={title}>
        <span title="Share on Whatsapp">
          <WhatsappIcon size={40} round/></span>
        </WhatsappShareButton><br />
        <EmailShareButton url={url} subject={title}>
        <span title="Share on Email">
          <EmailIcon size={40} round/></span>
        </EmailShareButton><br /> 
        <span title="Copy Link"><Button 
          className={classnames(classes.share_toggle, classes.clipboard_icon)}
          tone={3} 
          variant={4} 
          vol={2} 
          icon={<i className="fa fa-clipboard" style={{fontSize: 18}}/>}
          iconPos={'end'}
          onClick={() =>  navigator.clipboard.writeText(url)}
        ></Button></span>
        <br /> </div>: <div><br /><br /><br /><br /><br /><br /><br /><br /></div>
        }        
        {/* <button className={classnames(classes.share_toggle)}>
          {}
        </button> */}
        <Button 
          className={classnames(classes.share_toggle)}
          tone={2} 
          variant={4} 
          vol={2} 
          icon={(showResults) ? <i className="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-share-alt" aria-hidden="true" style={{fontSize: 18}}></i>} 
          iconPos={'end'}
          onClick={onClick}  
        >
        
        </Button>
      </div>
  );
};
export default SocialShare;