import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import { H1, Grid, Button, Icon } from "@actionishope/shelley";
import Banner from "../components/Banner/Banner";
import SocialShare from "../components/SocialSahre/SocialShare";
import { classes as spacing } from "@actionishope/shelley/styles/default/spacing.st.css";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";
import { classes as text } from "../styles/puma/text.st.css";
import "../styles/puma/faq-help.css"
import { useHistory } from "react-router-dom";

const FaqHelp = () => {

  const [question1, setQuestion1] = useState<boolean>(false);
  const [question2, setQuestion2] = useState<boolean>(false);
  const [question3, setQuestion3] = useState<boolean>(false);
  const [question4, setQuestion4] = useState<boolean>(false);
  const [question5, setQuestion5] = useState<boolean>(false);
  const [question6, setQuestion6] = useState<boolean>(false);
  const [question7, setQuestion7] = useState<boolean>(false);

  const minusIcon = () => {
    return <Icon viewBox="0 0 124 124"><path d="M112 50H12C5.4 50 0 55.4 0 62s5.4 12 12 12h100c6.6 0 12-5.4 12-12s-5.4-12-12-12z"></path></Icon>;
  }

  const plusIcon = () => {
    return <Icon><path d="M14 7h-5v-5h-2v5h-5v2h5v5h2v-5h5v-2z"></path></Icon>;
  }

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={spacing.mb8}>
      <Helmet>
          <title>{'Faq/Help - Solutions database'}</title>
          <meta name="description" content="Faq/Help Description"/>
          <meta property="og:title" content="Faq/Help"/>
          <meta property="og:description" content="Faq/Help Description"/>
          <meta property="og:image" content="https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg"/>
          <meta property="og:type" content="website"/>
      </Helmet>
      <Banner
        className={grid.edge}
        media={
          <div
            style={{
              opacity: 0.15,
              backgroundImage: `url(https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg)`,
              backgroundSize: "cover",
              backgroundPosition: "0 50%",
            }}
          />
        }
      >
       <H1 vol={8} uppercase>
          <span className={classnames(text.textBannerInline, text.textBg2)}>
            Faq/Help
          </span>
        </H1>
        <SocialShare title={'Faq/Help'} url={document.URL} description={'Faq/Help Description'}></SocialShare>
      </Banner> 
      <Grid tag="main" variant={1} formatted className={classnames(spacing.mt3)}>
        <div className = {classnames(grid.goal, "questionContainer")}>
          <h1>Frequently Asked Questions</h1>
          <p>Please read the questions below to see if your query is answered. If not, contact our team and we’ll be happy to help.</p>
          <Button 
            tone={false} 
            variant={false} 
            vol={3} 
            icon={question1 ? minusIcon() : plusIcon()} 
            iconPos={"start"} 
            onClick = {() => setQuestion1(previousState => !previousState)} 
          >
          Why do so many of the case studies, resources and tools relate to the Swansea region?
          </Button>
          {question1 &&
          <p>The website was developed through a partnership between <a href="http://swanseaenvironmentalforum.net/about/">Swansea Environmental Forum</a> and <a href="https://about.vocaleyes.org/">VocalEyes</a> Democracy CIC with funding from <a href ="https://www.swansea.gov.uk/council">Swansea Council</a> and <a href = "https://naturalresources.wales/?lang=en">Natural Resources Wales</a>. Whilst some of the information and case studies used in this resource relate to projects and activities in the Swansea area, this resource will be expanded to include material related to other locations.</p>
          }
          <br />
          <Button 
            tone={false} 
            variant={false} 
            vol={3} 
            icon={question2 ? minusIcon() : plusIcon()} 
            iconPos={"start"}  
            onClick = {() => setQuestion2(previousState => !previousState)} 
          >
            How do I suggest my own solutions?
          </Button>
          {question2 &&
          <p>If you have a suggestion for a solution that you would like to add to this online resource, please complete and submit this <span className="hyperLink" onClick={() => history.push('/suggestion')}>form</span>.</p>
          }
          <br />
          <Button 
            tone={false} 
            variant={false} 
            vol={3} 
            icon={question3 ? minusIcon() : plusIcon()} 
            iconPos={"start"}  
            onClick = {() => setQuestion3(previousState => !previousState)} 
          >
            How do I share a case study on this website?
          </Button>
          {question3 &&
          <p>If you are aware of a project that is a great example of one of the solutions featured on this website or you are running a project that you think could be featured as a case study, please complete and submit this <span className="hyperLink" onClick={() => history.push('/suggestion')}>form</span>.</p>
          }
          <br />
          <Button 
            tone={false} 
            variant={false} 
            vol={3} 
            icon={question4 ? minusIcon() : plusIcon()} 
            iconPos={"start"}  
            onClick = {() => setQuestion4(previousState => !previousState)} 
          >
            I submitted a suggestion but did not receive a reply
          </Button>
          {question4 && 
          <p>We carefully review all contributions and are very grateful to everyone who takes the time to submit one, but regretfully we can not respond to each contributor and unfortunately not all suggestions can be included on the site. Thank you for your support in advancing this digital library and please continue to submit suggestions.</p>
          }
          <br />
          <Button 
            tone={false} 
            variant={false} 
            vol={3} 
            icon={question5 ? minusIcon() : plusIcon()} 
            iconPos={"start"}  
            onClick = {() => setQuestion5(previousState => !previousState)} 
          >
            I submitted a suggestion but it did not appear on the website as I’d written it
          </Button>
          {question5 &&
          <p>Every contribution is subject to amendments by our editorial team to improve readability and/or accuracy of information. We are very grateful for every contribution we receive and thank you for advancing this resource.</p>
          }
          <br />
          <Button 
            tone={false} 
            variant={false} 
            vol={3} 
            icon={question6 ? minusIcon() : plusIcon()} 
            iconPos={"start"}  
            onClick = {() => setQuestion6(previousState => !previousState)} 
          >
            Who is this online resource for?
          </Button>
          {question6 &&
          <p>This online library aims to provide targeted guidance to meet the needs of different audiences - from individuals and households to community groups, schools, businesses, and councils.</p>
          }
          <br />
          <Button 
            tone={false} 
            variant={false} 
            vol={3} 
            icon={question7 ? minusIcon() : plusIcon()} 
            iconPos={"start"}  
            onClick = {() => setQuestion7(previousState => !previousState)} 
          >
            How do I know that the solutions will have a real impact?
          </Button>
          {question7 &&
          <p>To enable meaningful and science-based action, the solutions have been built around the <a href="https://unfoundation.org/what-we-do/issues/sustainable-development-goals/?gclid=Cj0KCQjwna2FBhDPARIsACAEc_U7kjG9Ce8DWrI7dMC370zRfMrHlqVghA24-FnIx1kXwkPw_nXWtLkaAl2REALw_wcB">UN Sustainable Development Goals,</a> the Welsh <a href="https://www.futuregenerations.wales/about-us/future-generations-act/">Well-being of Future Generations goals,</a> the <a href="https://www.legislation.gov.uk/anaw/2016/3/contents/enacted">Environment Wales Act</a>, <a href="https://naturalresources.wales/about-us/area-statements/?lang=en">NRW Local Area Statements</a> and other local strategies. This ensures that even when making personal lifestyle changes - which can at times feel insignificant in the face of global problems - we’re all working toward the same aims.</p>
          }
        </div>
      </Grid>
    </div>
  );
};

export default FaqHelp;
