import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import { H1, Grid } from "@actionishope/shelley";
import Banner from "../components/Banner/Banner";
import SocialShare from "../components/SocialSahre/SocialShare";
import { classes as spacing } from "@actionishope/shelley/styles/default/spacing.st.css";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";
import { classes as text } from "../styles/puma/text.st.css";
import "../styles/puma/aboutUs.css";
import swanseaImage from "../images/swansea.png";
import vocaleyesLogo from "../images/vocaleyes-logo-new.jpg"

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={spacing.mb8}>
      <Helmet>
          <title>{'About - Solutions database'}</title>
          <meta name="description" content="About Description"/>
          <meta property="og:title" content="About"/>
          <meta property="og:description" content="About Description"/>
          <meta property="og:image" content="https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg"/>
          <meta property="og:type" content="website"/>
      </Helmet>
      <Banner
        className={grid.edge}
        media={
          <div
            style={{
              opacity: 0.15,
              backgroundImage: `url(https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg)`,
              backgroundSize: "cover",
              backgroundPosition: "0 50%",
            }}
          />
        }
      >
       <H1 vol={8} uppercase>
          <span className={classnames(text.textBannerInline, text.textBg2)}>
            About Us
          </span>
        </H1>
        <SocialShare title={'About'} url={document.URL} description={'About Description'}></SocialShare>
      </Banner> 
      <Grid tag="main" variant={1} formatted className={classnames(spacing.mt3)}>
        <div className={classnames(grid.goal, "aboutContainer")}>
          <h1>About us</h1>
          <p>The aim of this online resource is to provide practical solutions to help individuals, communities and organisations take positive actions to address the climate emergency and support nature recovery.</p>
          <p>It was developed through a partnership between Swansea Environmental Forum and VocalEyes Democracy CIC with funding from Swansea Council and Natural Resources Wales.</p>
          <p>Swansea Environmental Forum is the strategic partnership for all aspects of the natural and built environment in Swansea. Find out more about <a href="http://swanseaenvironmentalforum.net/about" target="_blank" rel="noopener noreferrer"> [SEF and its activities ]</a>.</p>
          <p>VocalEyes provides innovative online platforms and teaches digital skills to help organisations and communities turn good ideas into action through digital democracy, crowd-sourcing and participatory budgeting tools. Find out more <a href="https://about.vocaleyes.org/" target="_blank" rel="noopener noreferrer">[ about VocalEyes ]</a>.</p>
          <p>Special thanks are given to Bethan John and David Thorpe for their research work and helping to create this resource.</p>
          <p>Whilst some of the information and case studies used in this resource relate to projects and activities in the Swansea area, all of the ideas shared are applicable anywhere and it is anticipated that this resource will be expanded to include material related to other locations.</p>
          <p><div className="logoContainer">
            <a href="http://swanseaenvironmentalforum.net" target="_blank" rel="noopener noreferrer"><img src="https://user-images.githubusercontent.com/81249327/125482702-10b69935-75cb-4f29-9702-d885e68d280f.jpg" alt="swansea forum"></img></a>
            <a href="https://naturalresourceswales.gov.uk" target="_blank" rel="noopener noreferrer"><img src="https://naturalresourceswales.gov.uk/images/nrw-logo.svg" alt="Natural Resources Wales"></img></a>
            <a href = "https://www.swansea.gov.uk" target="_blank" rel="noopener noreferrer"><img src={swanseaImage} alt="swansea"></img></a>
            <a href="https://vocaleyes.org" target="_blank" rel="noopener noreferrer"><img src={vocaleyesLogo} alt="vocaleyes"></img></a>
          </div></p>
        </div>
      </Grid>
    </div>
  );
};

export default About;
