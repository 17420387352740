import React, {useState, useEffect} from "react";
import classnames from "classnames";
import { st, classes } from "./header.st.css";
import { Grid, GridProps, Text, Button } from "@actionishope/shelley";
import { Link } from "react-router-dom";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";
import { useHistory, useLocation } from 'react-router-dom';

// @ts-ignore
import { useMediaQuery } from 'react-responsive';

const Header = ({ className: classNameProp, children, ...rest }: GridProps) => {
  const headerTypes: any = {
    home: "home",
    challenges: "challenges",
    solutions: "solutions",
    case_studies: "case-studies"
  }
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const [burgerManager, setBurgerManager] = useState<boolean>(false);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('')
  const [headerSelected, setheaderSelected] = useState<any>();
  const location = useLocation();

  const findHeaderToSelect = (path: any) => {
    switch (path){
      case "/":
        setheaderSelected(headerTypes.home);
        break;
      case "/solutions":
        setheaderSelected(headerTypes.solutions);
        break;
      case "/challenges":
        setheaderSelected(headerTypes.challenges);
        break;
      case "/case-studies":
        setheaderSelected(headerTypes.case_studies);
        break;
      default:
        if(path.indexOf("/solutions") !== -1){
          setheaderSelected(headerTypes.solutions);
        } else if(path.indexOf("/case-studies") !== -1) {
          setheaderSelected(headerTypes.case_studies);
        }
    }
  }

  useEffect(() => {
    const path = window.location.pathname;
    findHeaderToSelect(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    findHeaderToSelect(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    !isTabletOrMobile && setBurgerManager(false);
  },[isTabletOrMobile]);

  const routeHelper = (route: string) => {
    history.push(route); 
    setBurgerManager(false);
  }

  const searchRoute = () => {
    if( searchQuery ){
      history.push('/search_results/'+searchQuery)
    }
  }
  
  return (
    <Grid
      tag="header"
      className={st(classnames(classes.root, classNameProp, burgerManager && classes.header_burger_border))}
      {...rest}
    >
      {/* {children} */}
      <nav className={grid.goal}>
        <div className={classes.flex_spaceBetween}>
        {isTabletOrMobile ? <div className={classes.burger_style} onClick={() => {setBurgerManager(!burgerManager)}}>
            <div></div>
            <div></div>
            <div></div>
          </div> : <ul className={classes.navList}>
          <Text uppercase vol={4} as="li">
            <Link className={classnames(classes.navItem, headerSelected === headerTypes.home ? classes.on_select: null)} to="/" onClick={() => setheaderSelected(headerTypes.home)}>
              Home
            </Link>
          </Text>

          <Text uppercase vol={4} as="li">
            <Link className={classnames(classes.navItem, headerSelected === headerTypes.challenges ? classes.on_select: null)} to="/challenges" onClick={() => setheaderSelected(headerTypes.challenges)}>
              Challenges
            </Link>
          </Text>

          <Text uppercase vol={4} as="li">
            <Link className={classnames(classes.navItem, headerSelected === headerTypes.solutions ? classes.on_select: null)} to="/solutions"  onClick={() => setheaderSelected(headerTypes.solutions)}>
              Solutions
            </Link>
          </Text>

          <Text uppercase vol={4} as="li">
            <Link className={classnames(classes.navItem, headerSelected === headerTypes.case_studies ? classes.on_select: null)} to="/case-studies"  onClick={() => setheaderSelected(headerTypes.case_studies)}>
              Case Studies
            </Link>
          </Text>
        </ul> }                 
        <div className={classes.search_div}>
        <input type="text" id="search_query" 
          name="search_query" 
          placeholder="Type Here.."
          value={searchQuery}
          className={classes.search_field}
          onKeyPress={(e) => {
            if(e.key === "Enter"){
              searchRoute();
            }
            }}
          onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button tone={4} variant={3} vol={3} className={classes.search_button} as="li" onClick={()=>searchRoute()} >
          Search
        </Button>
        </div>
        </div>
      </nav>
      <br/>      
      <div className={classnames(grid.goal, classes.burger_options)} hidden={!burgerManager}>
      <div>
        <p onClick={() => routeHelper('/')}>Home</p>
      </div>
      <div>
        <p onClick={() => routeHelper('/challenges')}>Challenges</p>
      </div>
      <div>
        <p onClick={() => routeHelper('/solutions')}>Solutions</p>
      </div>
      <div className={classes.last_div}>
        <p onClick={() => routeHelper('/case-studies')}>Case Studies</p>
      </div>
      </div>     
    </Grid>
  );
};

export default Header;
