export const sortFilterOptions = (response: any) => {
    var sortedObject: any;
      sortedObject = {
        data: [],
        meta: {}
      };
      var sortedCategories = response.data.data.map((value: any) => {
        return value.code;
      });
      sortedCategories.sort();
      sortedCategories.forEach((element: any) => {
        for(var index in response.data.data){
          if(response.data.data[index].code === element){
            sortedObject.data.push(response.data.data[index]);
            break;
          }
        }
      });
      sortedObject.meta = response.data.meta;
      return sortedObject;
}