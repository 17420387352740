import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { st, classes } from "./footer.st.css";

import { Grid, GridProps, Text } from "@actionishope/shelley";
import { Link } from "react-router-dom";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";

const Footer = ({ className: classNameProp, children, ...rest }: GridProps) => {
  const footerTypes: any = {
    aboutUs: "aboutUs",
    suggestContent: "suggestContent",
    contactUs: "contactUs",
    faq_help: "faq_help",
    privacyPolicy: "privacyPolicy"
  }
  const [footerSelected, setFooterSelected] = useState<any>();
  useEffect(() => {
    const path = window.location.pathname;
    switch (path){
      case "/about":
        setFooterSelected(footerTypes.aboutUs);
        break;
      case "/suggestion":
        setFooterSelected(footerTypes.suggestContent);
        break;
      case "/contact-us":
        setFooterSelected(footerTypes.contactUs);
        break;
      case "/faq_help":
        setFooterSelected(footerTypes.faq_help);
        break;
      case "/privacypolicy":
        setFooterSelected(footerTypes.privacyPolicy);
        break;
      default:
        if(path.indexOf("/suggestion") !== -1){
          setFooterSelected(footerTypes.suggestContent);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <Grid
      tag="footer"
      className={st(classnames(classes.root, classNameProp))}
      {...rest}
    >
      <nav className={grid.goal}>
        <ul className={classes.navList}>
          <Text uppercase vol={4} as="li">
            <Link className={classnames(classes.navItem, footerSelected === footerTypes.aboutUs ? classes.on_select: null)} to="/about" onClick={() => setFooterSelected(footerTypes.aboutUs)}>
              About Us
            </Link>
          </Text>
          <Text uppercase vol={4} as="li">
            <Link className={classnames(classes.navItem, footerSelected === footerTypes.suggestContent ? classes.on_select: null)} onClick={() => setFooterSelected(footerTypes.suggestContent)} to="/suggestion">
              Suggest Content
            </Link>
          </Text>
          <Text uppercase vol={4} as="li">
          <Link className={classnames(classes.navItem, footerSelected === footerTypes.contactUs ? classes.on_select: null)} onClick={() => setFooterSelected(footerTypes.contactUs)} to="/contact-us">
              CONTACT US
            </Link>
          </Text>
          <Text uppercase vol={4} as="li">
          <Link className={classnames(classes.navItem, footerSelected === footerTypes.faq_help ? classes.on_select: null)} onClick={() => setFooterSelected(footerTypes.faq_help)} to="/faq_help">
              FAQ/HELP
            </Link>
          </Text>
          <Text uppercase vol={4} as="li">
          <Link className={classnames(classes.navItem, footerSelected === footerTypes.privacyPolicy ? classes.on_select: null)} onClick={() => setFooterSelected(footerTypes.privacyPolicy)} to="/privacypolicy">
              PRIVACY POLICY
            </Link>
          </Text>
        </ul>
      </nav>

      {children}
    </Grid>
  );
};

export default Footer;
