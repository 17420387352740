import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { H1, H2, P, Grid, Button } from "@actionishope/shelley";
import Banner from "../components/Banner/Banner";
import SocialShare from "../components/SocialSahre/SocialShare";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";
import { classes as text } from "../styles/puma/text.st.css";
import { classes as spacing } from "../styles/puma/spacing.st.css";
import { classes as buttons } from "../styles/puma/button.st.css";
import { api, slug } from "../api";
import Card from "../components/Card/Card";
import { postData } from "../interface/postData";
import "../styles/puma/filter.css";
import Select from "react-select";
import { sortFilterOptions } from "../supportFunctions";
import "../styles/puma/home.css";
// @ts-ignore
import { useMediaQuery } from "react-responsive";

interface challengesProps {
  group: any;
}

const Home = ({ group }: challengesProps) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const [solutions, setSolutions] = useState<any>();
  const [caseStudies, setCaseStudies] = useState<any>();
  const [mainCategories, setMainCategories] = useState<any>();
  const [organisationTypes, setOrganisationTypes] = useState<any>();
  // const [stakeHolders, setStakeHolders] = useState<any>();
  let filterData: postData = {
    groups: [10479],
    topics: [],
    categories: [],
    q: "",
    categoryOperator: "AND",
  };
  const [topicSelected, setTopic] = useState<any>();
  const [categorySelected, setCategory] = useState<any>();
  const [organisationTypeSelected, setOrganisationType] = useState<any>();
  // const [stakeHolderSelected, setStakeHolder] = useState<any>();
  const isFirstRun = useRef(true);
  // const [allSolutionsSelected, setallSolutionsDataFormatSelected] = useState<any>(false);
  let solutionsData: any;
  const history = useHistory();

  const loadAllSolutions = () => {
    // API for solutions
    api
      .get(`/solutions/10479?per-page=3`)
      .then(async (response) => {
        solutionsData = response.data.data;
        setSolutions({
          data: solutionsData,
        });
        // setallSolutionsDataFormatSelected(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadsolutionsOfTopic = (key: string) => {
    api
      .get(`/topics/ideas/${key}?per-page=15`)
      .then(async (response) => {
        setSolutions((prevState: any) => {
          return {
            ...prevState,
            data: response.data.data,
          };
        });
        // setallSolutionsDataFormatSelected(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadTopic = (topicKey: string) => {
    loadsolutionsOfTopic(topicKey);
    api
      .get(`/topics/${topicKey}?per-page=15`)
      .then(async (response) => {
        const data = response.data.data[0];
        setSolutions((prevState: any) => {
          return {
            ...prevState,
            name: data.name,
            description: data.description,
            key: data.key,
            images: data.images,
            mainCategores:
              data.categories && data.categories.main_categories
                ? data.categories.main_categories.items
                : false,
            orgTypes:
              data.categories && data.categories.organisation_types
                ? data.categories.organisation_types.items
                : false,
            stakeholders:
              data.categories && data.categories.stakeholders
                ? data.categories.stakeholders.items
                : false,
          };
        });
        // setallSolutionsDataFormatSelected(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadAllSolutions();
    // API for case-studies
    api
      .get("/news?per-page=15")
      .then(async (response) => {
        setCaseStudies(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // API for main categories
    api
      .get("/categories/main_categories")
      .then(async (response) => {
        var sortedObject: any = sortFilterOptions(response);
        console.log("sorted_categories", sortedObject);
        console.log("categories:", response.data);
        setMainCategories(sortedObject);
      })
      .catch((error) => {
        console.log(error);
      });
    // API for organisation types
    api
      .get("/categories/organisation_types")
      .then(async (response) => {
        var sortedObject: any = sortFilterOptions(response);
        console.log("sorted_organization_types", sortedObject);
        console.log("organisation_types:", response.data);
        setOrganisationTypes(sortedObject);
      })
      .catch((error) => {
        console.log(error);
      });
    // API for StakeHolders
    // api.get("/categories/stakeholders")
    // .then(async (response) => {
    //   var sortedObject: any = sortFilterOptions(response);
    //   console.log("sorted_stakeholders", sortedObject);
    //   console.log('stakeholders:',response.data);
    //   setStakeHolders(sortedObject);
    // })
    // .catch((error)=>{
    //   console.log(error);
    // })
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterData.topics = [];
    filterData.categories = [];
    topicSelected && filterData.topics.push(topicSelected.value);
    categorySelected && filterData.categories.push(categorySelected);
    organisationTypeSelected &&
      filterData.categories.push(organisationTypeSelected);
    // stakeHolderSelected && filterData.categories.push(stakeHolderSelected);
    console.log("Filter Options", filterData);
    if (filterData.categories.length !== 0) {
      console.log(filterData);
      api
        .post(`/ideas/search`, JSON.stringify(filterData))
        .then(async (response) => {
          const data = response.data.data;
          setSolutions({
            data: data,
          });
          // setallSolutionsDataFormatSelected(true);
          console.log("Response ", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (filterData.topics.length !== 0) {
      loadTopic(filterData.topics[0].toString());
    } else {
      loadAllSolutions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicSelected, categorySelected, organisationTypeSelected]);

  const routeTo = (url: string) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Helmet>
        <title>Solutions database</title>
        <meta
          name="description"
          content="Practical solutions for saving the planet"
        />
        <meta property="og:title" content="Inspiration Hub - Climate & Nature Solutions" />
        <meta
          property="og:description"
          content="Practical solutions for saving the planet"
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Banner
        className={grid.edge}
        media={
          <div
            style={{
              opacity: 0.9,
              backgroundImage: `url(https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg`,
              backgroundSize: "cover",
              backgroundPosition: "0 50%",
            }}
          />
        }
      >
        <H1 className={grid.goal} vol={8} uppercase>
          <span className={text.textBg}>CLIMATE &</span>
          <br />
          <span className={classnames(text.textBannerInline, text.textBg2)}>NATURE</span>
        </H1>
        <br />

        <H2 className={grid.goal} vol={5}>
          <span className={text.textBg}>
            Practical solutions for saving the planet
          </span>
        </H2>
      </Banner>
      <Grid tag="div" className="homePageText" >
        <div className={grid.goal}>
        <p>Developed through a partnership between Swansea Environmental Forum and VocalEyes Democracy CIC with funding from Swansea Council and Natural Resources Wales... [ <a href="/about">read more</a> ]</p>
        </div></Grid>
      <Grid tag="main" variant={1} formatted>

      <div className={classnames(text.sectionLinkBanner, grid.edge)}>
          <H2 className={classnames(text.color2,text.center)} vol={7} uppercase>
            <i className="fa fa-ellipsis-h" aria-hidden="true" style={{fontSize: "40px"}}></i>Solutions<i className="fa fa-ellipsis-h" aria-hidden="true" style={{fontSize: "40px"}}></i>

          </H2>
          <P className={classnames(spacing.mb2, text.center)} vol={3}>
            Discover step-by-step guides to taking environmental action
          </P>
          <div className={`${grid.goal} filterSection`}>
            <div className="filterBar" id="stickyHeader">
              <p className="filterText">
                <b>Filter: </b>Select from 1 or more options for personalised
                content
              </p>
              <div
                className={
                  isTabletOrMobile ? "filterGroupMobile" : "filterGroup"
                }
              >
                {/* <i className="fa fa-filter" aria-hidden="true" style={{fontSize: "40px"}}></i> */}
                {/* <Select className = "inputFilter" isClearable
            placeholder="WHO YOU ARE..."
            options={stakeHolders && stakeHolders.data.map((stakeHolder: any)=>{
              return ({label: stakeHolder.name, value: stakeHolder.code})
            })}
            onChange={(e) => {
              setStakeHolder(e && e.value)
            }}
          /> */}
                <Select
                  className={`inputFilter ${
                    isTabletOrMobile ? "inputFilterMobile" : "inputFilterWidth"
                  }`}
                  isClearable
                  placeholder="Your group or organisation..."
                  options={
                    organisationTypes &&
                    organisationTypes.data.map((organisation: any) => {
                      return {
                        label: organisation.name,
                        value: organisation.code,
                      };
                    })
                  }
                  onChange={(e) => {
                    setOrganisationType(e && e.value);
                  }}
                />
                <Select
                  className={`inputFilter ${
                    isTabletOrMobile ? "inputFilterMobile" : "inputFilterWidth"
                  }`}
                  isClearable
                  placeholder="A topic of interest..."
                  options={
                    mainCategories &&
                    mainCategories.data.map((categories: any) => {
                      return { label: categories.name, value: categories.code };
                    })
                  }
                  onChange={(e) => {
                    setCategory(e && e.value);
                  }}
                />
                <Select
                  className={`inputFilter ${
                    isTabletOrMobile ? "inputFilterMobile" : "inputFilterWidth"
                  }`}
                  isClearable
                  placeholder="A type of challenge..."
                  value={topicSelected}
                  options={
                    group &&
                    group.links.topics.map((topic: any) => {
                      return { label: topic.name, value: topic.key };
                    })
                  }
                  onChange={(e) => {
                    setTopic(e && e);
                  }}
                />
              </div>
            </div>
            <br />
          </div>
          {solutions && solutions.data && (
            <div className={grid.goal}>
              {solutions.data.length !== 0 ? (
                <Grid variant={4}>
                  {solutions.data ? (
                    solutions.data.map((item: any) => {
                      return (
                        <Card
                          title={item.name && item.name}
                          url={`/solutions/${item.key}/${
                            item.name && slug(item.name)
                          }`}
                          description={
                            item.excerpt ? item.excerpt : item.description
                          }
                          media={item.images[0]}
                          key={item.key}
                        />
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </Grid>
              ) : (
                <P className="noSolutions">
                  No solutions found. Try changing the filters above for better
                  results.
                </P>
              )}
            </div>
          )}
          {/* {solutions && (
          <div className={grid.edge}>
            <Grid variant={4}>
              {solutions ? (
               solutions.map((item: any, index: number) => {
                if(index < 3){
                  return (
                    <Card
                      title={item.name}
                      url={`/solutions/${item.key}/${item.name}`}
                      description={item.excerpt ? item.excerpt : item.description}
                      media={item.images[0]}
                      key={item.key}
                    />
                  );} else {
                    return null;
                  }
                })
              ) : (
                  <P>No results</P>
                )}
            </Grid>
          </div>
        )} */}
          <Grid
            variant={2}
            formatted
            className={classnames(
              spacing.mt2,
              spacing.mt4,
              spacing.mb8,
              grid.pen
            )}
          >
            <Button
              className={buttons.link}
              variant={3}
              tone={5}
              vol={6}
              role="link"
              fullWidth
              onClick={() => routeTo("/solutions")}
            >
              Find more solutions
            </Button>
            <Button
              className={buttons.link}
              variant={3}
              tone={5}
              vol={6}
              role="link"
              fullWidth
              onClick={() => routeTo("/suggestion/solution")}
            >
              Suggest a solution
            </Button>
            {/* <Link to="/suggestion/solution" className={buttons.link}>
          Suggest a solution
        </Link> */}
          </Grid>
        </div>
        <div className={classnames(text.sectionLinkBanner, grid.edge)}>
          <H2
            className={classnames(text.color1, text.center)}
            vol={7}
            uppercase
          >
            <Link to="/case-studies">
              <i
                className="fa fa-ellipsis-h"
                aria-hidden="true"
                style={{ fontSize: "40px" }}
              ></i>
              Case Studies
              <i
                className="fa fa-ellipsis-h"
                aria-hidden="true"
                style={{ fontSize: "40px" }}
              ></i>
            </Link>
          </H2>
          <P className={classnames(spacing.mb2, text.center)} vol={3}>
            Get inspired by the trailblazers who have created positive change
          </P>
          {caseStudies && (
            <div className={grid.edge}>
              <Grid variant={4}>
                {caseStudies ? (
                  caseStudies.map((item: any, index: number) => {
                    if (index < 3) {
                      return (
                        <Card
                          title={item.title}
                          url={`/case-studies/${item.key}/${slug(item.title)}`}
                          description={
                            item.excerpt ? item.excerpt : item.description
                          }
                          media={item.images[0]}
                          key={item.key}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <P>No results</P>
                )}
              </Grid>
            </div>
          )}
          <Grid
            variant={2}
            formatted
            className={classnames(
              spacing.mt2,
              spacing.mt4,
              spacing.mb8,
              grid.pen
            )}
          >
            <Button
              className={buttons.link}
              variant={3}
              tone={5}
              vol={6}
              role="link"
              fullWidth
              onClick={() => routeTo("/case-studies")}
            >
              Find more case studies
            </Button>
            {/* <Link to="/case-studies" className={buttons.link}>
              Find more case studies
            </Link> */}
            <Button
              className={buttons.link}
              variant={3}
              tone={5}
              vol={6}
              role="link"
              fullWidth
              onClick={() => routeTo("/suggestion/case-study")}
            >
              Suggest a case study
            </Button>
            {/* <Link to="/suggestion/case-study" className={buttons.link}>
              Suggest a case study
            </Link> */}
          </Grid>
        </div>
        <div className={classnames(text.sectionLinkBanner, grid.edge)}>
          <H2
            className={classnames(text.color3, text.center)}
            vol={7}
            uppercase
          >
            <Link to="/challenges">
              <i
                className="fa fa-ellipsis-h"
                aria-hidden="true"
                style={{ fontSize: "40px" }}
              ></i>
              Challenges
              <i
                className="fa fa-ellipsis-h"
                aria-hidden="true"
                style={{ fontSize: "40px" }}
              ></i>
            </Link>
          </H2>
          <P className={classnames(spacing.mb2, text.center)} vol={3}>
            Explore & overcome the most urgent environmental issues
          </P>
          {group && (
            <div className={classnames(grid.edge)}>
              <Grid variant={4}>
                {group.links.topics ? (
                  group.links.topics.map((item: any, index: number) => {
                    if (index < 3) {
                      return (
                        <Card
                          title={item.name}
                          url={`/solutions/${item.key}`}
                          description={
                            item.excerpt ? item.excerpt : item.description
                          }
                          media={item.images && item.images[0]}
                          key={item.key}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <P>No results</P>
                )}
              </Grid>
            </div>
          )}
          <Grid
            variant={2}
            formatted
            className={classnames(
              spacing.mt2,
              spacing.mt4,
              spacing.mb8,
              grid.pen
            )}
          >
            <Button
              className={buttons.link}
              variant={3}
              tone={5}
              vol={6}
              role="link"
              fullWidth
              onClick={() => routeTo("/challenges")}
            >
              Find more challenges
            </Button>
            {/* <Link to="/challenges" className={buttons.link}>
              Find more challenges
            </Link> */}
            <Button
              className={buttons.link}
              variant={3}
              tone={5}
              vol={6}
              role="link"
              fullWidth
              onClick={() => routeTo("/suggestion/challenge")}
            >
              Suggest a challenge
            </Button>
            {/* <Link to="/suggestion/challenge" className={buttons.link}>
              Suggest a challenge
            </Link> */}
          </Grid>
        </div>
      </Grid>
      <SocialShare
        title={"Home page"}
        url={document.URL}
        description="Practical solutions for saving the planet"
      ></SocialShare>
    </div>
  );
};

export default Home;
