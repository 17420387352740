import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";
import Banner from "../components/Banner/Banner";
import SocialShare from "../components/SocialSahre/SocialShare";
import Card from "../components/Card/Card";
import { api, slug } from "../api";
import {
  H1,
  H2,
  Grid,
  Button,
  VisuallyHidden,
  P,
  H3,
  Icon,
} from "@actionishope/shelley";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";
import { classes as text } from "../styles/puma/text.st.css";
import { classes as spacing } from "../styles/puma/spacing.st.css";
import { classes as buttons } from "../styles/puma/button.st.css";
import { useParams, useLocation } from "react-router-dom";
import "../styles/puma/filter.css";
import "font-awesome/css/font-awesome.min.css";
import Select from "react-select";
import { postData } from "../interface/postData";
import { sortFilterOptions } from "../supportFunctions";
// @ts-ignore
import { useMediaQuery } from "react-responsive";

interface ChallengesProps {
  group: any;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Solutions = ({ group }: ChallengesProps) => {
  const defaultBgImage =
    "https://ik.imagekit.io/tcvka0ufln/solutions1_Rpskm-eQ_.jpg";
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const [content, setContent] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>();
  const [allSolutionsSelected, setallSolutionsDataFormatSelected] =
    useState<any>(false);
  const params: any = useParams();
  const [mainCategories, setMainCategories] = useState<any>();
  const [organisationTypes, setOrganisationTypes] = useState<any>();
  // const [stakeHolders, setStakeHolders] = useState<any>();
  let filterData: postData = {
    groups: [10479],
    topics: [],
    categories: [],
    q: "",
    categoryOperator: "AND",
  };
  const [topicSelected, setTopic] = useState<any>();
  const [categorySelected, setCategory] = useState<any>();
  const [organisationTypeSelected, setOrganisationType] = useState<any>();
  const [expandCards, setExpandCards] = useState<boolean>(true);
  // const [stakeHolderSelected, setStakeHolder] = useState<any>();
  const isFirstRun = useRef(true);
  const query = useQuery();
  let solutionsData: any;
  const solutionsLoaded = useRef<any>(false);
  let cat : string|null = query.get("cat");
  let org : string|null = query.get("org");

  const loadsolutionsOfTopic = (key: string) => {
    api
      .get(`/topics/ideas/${key}?per-page=15`)
      .then(async (response) => {
        setContent((prevState: any) => {
          return {
            ...prevState,
            data: response.data.data,
          };
        });
        setMetaData(response.data.meta);
        setallSolutionsDataFormatSelected(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadTopic = (topicKey: string) => {
    loadsolutionsOfTopic(topicKey);
    api
      .get(`/topics/${topicKey}?per-page=15`)
      .then(async (response) => {
        const data = response.data.data[0];
        setContent((prevState: any) => {
          return {
            ...prevState,
            name: data.name,
            description: data.description,
            key: data.key,
            images:
              data.categories &&
              data.categories.main_categories.items[0].cat_image,
            mainCategores:
              data.categories && data.categories.main_categories
                ? data.categories.main_categories.items
                : false,
            orgTypes:
              data.categories && data.categories.organisation_types
                ? data.categories.organisation_types.items
                : false,
            stakeholders:
              data.categories && data.categories.stakeholders
                ? data.categories.stakeholders.items
                : false,
          };
        });
        setallSolutionsDataFormatSelected(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const metaDataResponseLoad = (response: any) => {
    const data = response.data.data;
    if (allSolutionsSelected) {
      setContent({
        data: data,
      });
    } else {
      setContent((prevState: any) => {
        return {
          ...prevState,
          data: data,
        };
      });
    }
  };

  const loadAllSolutions = () => {
    api
      .get(`/solutions/10479?per-page=15`)
      .then(async (response) => {
        solutionsData = response.data.data;
        setContent({
          data: solutionsData,
        });
        setMetaData(response.data.meta);
        setallSolutionsDataFormatSelected(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    params.topicId &&
      group &&
      group.links.topics.forEach((topic: any) => {
        if (params.topicId === topic.key.toString()) {
          setTopic({ label: topic.name, value: topic.key });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  useEffect(() => {
    // Get the default topic data.
    if (params.topicId == null && !solutionsLoaded.current && (cat == null || org == null)) {
      solutionsLoaded.current = true;
      loadAllSolutions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cat && mainCategories && mainCategories.data.forEach((element : any) => {
      if(cat === element.code){
        setCategory({label:element.name ,value:element.code});
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[mainCategories])

  useEffect(() => {
    org && organisationTypes && organisationTypes.data.forEach((element: any) => {
      if(org === element.code){
        setOrganisationType({label:element.name ,value:element.code});
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[organisationTypes])

  useEffect(() => {
    // API for main categories
    api
      .get("/categories/main_categories")
      .then(async (response) => {
        var sortedObject: any = sortFilterOptions(response);
        console.log("sorted_categories", sortedObject);
        console.log("categories:", response.data);
        setMainCategories(sortedObject);
      })
      .catch((error) => {
        console.log(error);
      });
    // API for organisation types
    api
      .get("/categories/organisation_types")
      .then(async (response) => {
        var sortedObject: any = sortFilterOptions(response);
        console.log("sorted_organization_types", sortedObject);
        console.log("organisation_types:", response.data);
        setOrganisationTypes(sortedObject);
      })
      .catch((error) => {
        console.log(error);
      });
    // API for StakeHolders
    // api.get("/categories/stakeholders")
    // .then(async (response) => {
    //   var sortedObject: any = sortFilterOptions(response);
    //   console.log("sorted_stakeholders", sortedObject);
    //   console.log('stakeholders:',response.data);
    //   setStakeHolders(sortedObject);
    // })
    // .catch((error)=>{
    //   console.log(error);
    // })
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterData.topics = [];
    filterData.categories = [];
    topicSelected && filterData.topics.push(topicSelected.value);
    categorySelected && filterData.categories.push(categorySelected.value);
    organisationTypeSelected &&
      filterData.categories.push(organisationTypeSelected.value);
    // stakeHolderSelected && filterData.categories.push(stakeHolderSelected);
    console.log("Filter Options", filterData);
    if (filterData.categories.length !== 0) {
      console.log(filterData);
      api
        .post(`/ideas/search`, JSON.stringify(filterData))
        .then(async (response) => {
          const data = response.data.data;
          setContent({
            data: data,
          });
          setMetaData(response.data.meta);
          setallSolutionsDataFormatSelected(true);
          console.log("Response ", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (filterData.topics.length !== 0) {
      loadTopic(filterData.topics[0].toString());
    } else {
      solutionsLoaded.current = true;
      loadAllSolutions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicSelected, categorySelected, organisationTypeSelected]);

  useEffect(() => {
    const stickyHeader: HTMLElement | null =
      document.getElementById("stickyHeader");
    const sticky = stickyHeader && stickyHeader.offsetTop;
    window.addEventListener("scroll", () => {
      if (sticky) {
        if (window.pageYOffset > sticky) {
          stickyHeader && stickyHeader.classList.add("sticky");
        } else {
          stickyHeader && stickyHeader.classList.remove("sticky");
        }
      }
    });
  }, []);

  const renderCard = () => {
    return (
      <div className={grid.goal}>
        {content.data.length !== 0 ? (
          <Grid variant={4}>
            {content.data ? (
              content.data.map((item: any) => {
                return (
                  <Card
                    title={item.name && item.name}
                    url={`/solutions/${item.key}/${
                      item.name && slug(item.name)
                    }`}
                    description={item.excerpt ? item.excerpt : item.description}
                    media={item.images[0]}
                    key={item.key}
                    isCollapsed={!expandCards}
                  />
                );
              })
            ) : (
              <div></div>
            )}
          </Grid>
        ) : (
          <P className="noSolutions">
            No solutions found. Try changing the filters above for better
            results.
          </P>
        )}
      </div>
    );
  };

  return (
    <div className={spacing.mb8}>
      <Helmet>
        <title>Solutions database</title>
        <meta
          name="description"
          content="Find solutions that are suited to you and framed around a specific challenge, the solutions are updated as they evolve."
        />
        <meta property="og:title" content="Solutions" />
        <meta
          property="og:description"
          content="Find solutions that are suited to you and framed around a specific challenge, the solutions are updated as they evolve."
        />

        <meta
          property="og:image"
          content="https://ik.imagekit.io/tcvka0ufln/solutions1_Rpskm-eQ_.jpg"
        />

        <meta property="og:type" content="website" />
      </Helmet>
      <Banner
        className={grid.edge}
        style={{
          marginBottom: "3vw",
        }}
        media={
          <div
            style={{
              opacity: 0.36,
              backgroundImage: allSolutionsSelected
                ? `url(${defaultBgImage})`
                : `url(${content.images})` ?? `url(${defaultBgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "0 50%",
            }}
          />
        }
      >
        <Grid variant={3}>
          <div>
            <H1 vol={8} uppercase>
              <span className={text.textBg}>CLIMATE &</span>
              <br />
              <span className={classnames(text.textBannerInline, text.textBg2)}>
                NATURE
              </span>
            </H1>
            <br />
            <H2 className={grid.goal} vol={5}>
              <span className={text.textBg}>
                Practical solutions for saving the planet
              </span>
            </H2>
          </div>
          {/* rgb(0 0 0 / 34%) */}
        </Grid>
      </Banner>
      <Grid tag="main" variant={1} >
        <VisuallyHidden>
          <H2 className={classnames(text.sectionHeader, grid.goal)} vol={7}>
            Results
          </H2>
        </VisuallyHidden>
        {allSolutionsSelected ? (
          <div className={grid.goal}></div>
        ) : (
          <div className={classnames(grid.goal, spacing.mb1)}>
            <H2 className={classnames(grid.goal, spacing.mb1)} vol={6}>
              {content && content.name}
            </H2>
            <P className={classnames(grid.goal, spacing.mb2)}>
              {content && content.description}
            </P>
            <P vol={2}>
              {content.mainCategores ||
              content.orgTypes ||
              content.stakeholders ? (
                <strong>Relevant for: </strong>
              ) : null}
              {content.mainCategores &&
                content.mainCategores.map((item: any, index: number) => {
                  const tail =
                    index !== content.mainCategores.length - 1
                      ? ", "
                      : content.orgTypes &&
                        content.orgTypes.length !== 0 &&
                        ", ";
                  return (
                    <span key={`cattypes${index}`}>
                      {item.cat_name}
                      {tail}
                    </span>
                  );
                })}
              {content.orgTypes &&
                content.orgTypes.map((item: any, index: number) => {
                  const tail =
                    index !== content.orgTypes.length - 1
                      ? ", "
                      : content.stakeholders &&
                        content.stakeholders.length !== 0 &&
                        ", ";
                  return (
                    <span key={`cattypes${index}`}>
                      {item.cat_name}
                      {tail}
                    </span>
                  );
                })}
              {content.stakeholders &&
                content.stakeholders.map((item: any, index: number) => {
                  const tail =
                    index !== content.stakeholders.length - 1 && ", ";
                  return (
                    <span key={`cattypes${index}`}>
                      {item.cat_name}
                      {tail}
                    </span>
                  );
                })}
            </P>
          </div>
        )}
        <div className={`${grid.goal} filterSection`}>
          <div className="filterBar" id="stickyHeader">
            <div className="filterContainer">
              <p className="filterText">
                <b>Filter: </b>Select from 1 or more options for personalised
                content
              </p>
              <div
                className={
                  isTabletOrMobile ? "filterGroupMobile" : "filterGroup"
                }
              >
                {/* <i className="fa fa-filter" aria-hidden="true" style={{fontSize: "40px"}}></i> */}
                {/* <Select className = "inputFilter" isClearable
            placeholder="WHO YOU ARE..."
            options={stakeHolders && stakeHolders.data.map((stakeHolder: any)=>{
              return ({label: stakeHolder.name, value: stakeHolder.code})
            })}
            onChange={(e) => {
              setStakeHolder(e && e.value)
            }}
          /> */}
                <Select
                  className={`inputFilter ${
                    isTabletOrMobile ? "inputFilterMobile" : "inputFilterWidth"
                  }`}
                  isClearable
                  placeholder="Your group or organisation..."
                  value={organisationTypeSelected}
                  options={
                    organisationTypes &&
                    organisationTypes.data.map((organisation: any) => {
                      return {
                        label: organisation.name,
                        value: organisation.code,
                      };
                    })
                  }
                  onChange={(e) => {
                    setOrganisationType(e && e);
                  }}
                />
                <Select
                  className={`inputFilter ${
                    isTabletOrMobile ? "inputFilterMobile" : "inputFilterWidth"
                  }`}
                  isClearable
                  placeholder="A topic of interest..."
                  value={categorySelected}
                  options={
                    mainCategories &&
                    mainCategories.data.map((categories: any) => {
                      return { label: categories.name, value: categories.code };
                    })
                  }
                  onChange={(e) => {
                    setCategory(e && e);
                  }}
                />
                <Select
                  className={`inputFilter ${
                    isTabletOrMobile ? "inputFilterMobile" : "inputFilterWidth"
                  }`}
                  isClearable
                  placeholder="A type of challenge..."
                  value={topicSelected}
                  options={
                    group &&
                    group.links.topics.map((topic: any) => {
                      return { label: topic.name, value: topic.key };
                    })
                  }
                  onChange={(e) => {
                    setTopic(e && e);
                  }}
                />
              </div>
            </div>
          </div>
          <br />
        </div>
        <div
          className={classnames(grid.goal, spacing.mb2, spacing.mt2, "solutions-header")}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <H3 className={classnames(text.color2, grid.goal)} vol={6} uppercase>
            Solutions:
          </H3>
          {expandCards ? (
            <Button
              tone={2}
              variant={3}
              vol={3}
              icon={
                <Icon viewBox="0 0 124 124">
                  <path d="M112 50H12C5.4 50 0 55.4 0 62s5.4 12 12 12h100c6.6 0 12-5.4 12-12s-5.4-12-12-12z"></path>
                </Icon>
              }
              iconPos={"end"}
              onClick={() => setExpandCards(false)}
            />
          ) : (
            <Button
              tone={2}
              variant={3}
              vol={3}
              icon={
                <Icon>
                  <path d="M14 7h-5v-5h-2v5h-5v2h5v5h2v-5h5v-2z"></path>
                </Icon>
              }
              iconPos={"end"}
              onClick={() => setExpandCards(true)}
            />
          )}
        </div>
        {content && content.data && expandCards && renderCard()}
        {content && content.data && !expandCards && renderCard()}

        <Grid
          variant={2}
          formatted
          className={classnames(spacing.mt4, spacing.mb4)}
        >
          {metaData && content.data.length !== 0 && (
            <>
              <Button
                className={buttons.link}
                variant={3}
                tone={5}
                vol={6}
                role="link"
                fullWidth
                disabled={!metaData.pagination.links.prev}
                onClick={() => {
                  // Change to smooth scroll and refs.
                  window.scrollTo(0, 0);
                  api
                    .get(metaData.pagination.links.prev)
                    .then(async (response) => {
                      metaDataResponseLoad(response);
                      setMetaData(response.data.meta);
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
              >
                Previous
              </Button>

              <Button
                className={buttons.link}
                variant={3}
                tone={5}
                vol={6}
                role="link"
                fullWidth
                disabled={!metaData.pagination.links.next}
                onClick={() => {
                  // Change to smooth scroll and refs.
                  window.scrollTo(0, 0);
                  api
                    .get(metaData.pagination.links.next)
                    .then(async (response) => {
                      metaDataResponseLoad(response);
                      setMetaData(response.data.meta);
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
              >
                Next
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <SocialShare
        title={content && content.name ? content.name : "Solutions"}
        url={document.URL}
        description="Find solutions that are suited to you and framed around a specific challenge, the solutions are updated as they evolve."
      ></SocialShare>
    </div>
  );
};

export default Solutions;
