import React, { useState } from "react";
import classnames from "classnames";
import { H3, P } from "@actionishope/shelley";
import { st, classes } from "./card.st.css";
import { Link, useParams } from "react-router-dom";
import  Highlighted  from "./TextHighlighted";
export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  media?: string;
  description?: string;
  url: string;
  /** HTML tag to render as the root for your grid. */
  tag?: string;
  /** Designed to toggle child formatting/alignment rules. */
  formatted?: boolean;
  /** Variant index. */
  variant?: number;
  isCollapsed?: boolean;
}

const Card = ({
  className: classNameProp,
  children,
  variant = 1,
  tag: tagName = "div",
  title,
  description,
  media,
  url,
  isCollapsed = false,
  ...rest
}: CardProps) => {
  const rootClassNames = classnames(classes.root, classNameProp);
  const [isCardExpanded] = useState<Boolean>(!isCollapsed);
  const params: any = useParams();
  return (
    <div className={st(rootClassNames,classes.card_default)} {...rest}>
      {isCardExpanded && <div className={classes.media}>
        <img
          src={
            media !== ""
              ? media
              : "https://ik.imagekit.io/tcvka0ufln/missing_6VOJII-WVC0k.png"
          }
          alt=""
        />
      </div>}
      <div className={classes.text}>
        <H3 className={classnames(classes.title, classes.titleStyle)} vol={5}>
          {/* <Link className={classes.link} to={url}>
            {title}
          </Link> */}

          <Link
            className={classes.link}
            to={{
              pathname: `${url}`,
              // Allows us to parse some info to the destination so we
              // can pre-fill data we have rather than wait for the api response.
              state: {
                title,
                description,
                media,
              },
            }}
            // href={`${url}`}
          >
            {<Highlighted text={title ? title : ''} highlight={params.searchText}></Highlighted>}
          </Link>
        </H3>
        {isCardExpanded && <P>{<Highlighted text={description ? description : ''} highlight={params.searchText}></Highlighted>}</P>}
        {isCardExpanded && children}
      </div>
      <div className={classes.minimizeContainer}>
        {/* {isCardExpanded ? <Button 
        tone={2} 
        variant={3} 
        vol={3} 
        icon={<Icon viewBox="0 0 124 124"><path d="M112 50H12C5.4 50 0 55.4 0 62s5.4 12 12 12h100c6.6 0 12-5.4 12-12s-5.4-12-12-12z"></path></Icon>} 
        iconPos={'end'}
        onClick={() => setIsCardExpanded(false)}
      /> :
        <Button 
        tone={2} 
        variant={3} 
        vol={3} 
        icon={<Icon><path d="M14 7h-5v-5h-2v5h-5v2h5v5h2v-5h5v-2z"></path></Icon>} 
        iconPos={'end'}
        onClick={() => setIsCardExpanded(true)}
      />} */}
      </div>
    </div>
  );
};

export default Card;
