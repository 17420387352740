import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import { H1, Grid, InputText, Button } from "@actionishope/shelley";
import Banner from "../components/Banner/Banner";
import SocialShare from "../components/SocialSahre/SocialShare";
import { classes as spacing } from "@actionishope/shelley/styles/default/spacing.st.css";
import { classes as grid } from "@actionishope/shelley/styles/default/grid.st.css";
import { classes as text } from "../styles/puma/text.st.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../styles/puma/contact-us.css";
import axios from "axios";

type ContactUsInputs = {
  name: string,
  email: string,
  message: string,
};

const emailRegex : RegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

const ContactUs = () => {

  const { register, handleSubmit, errors, reset } = useForm<ContactUsInputs>();

  const onSubmit: SubmitHandler<ContactUsInputs> = data => {
    console.log(JSON.stringify(data));
    axios.post("https://2zxjdrcbe6.execute-api.eu-west-2.amazonaws.com/v1/suggestions", JSON.stringify(data),  {
      headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'gma5wSaqQv8bZ6dVgF4JA1C64dO3pVvw5C8IsLbp'
      }
    })
    .then(async(response) => {
      console.log(response.data)
      toast("Message sent!", {type: "success"})
      //reset the form
      reset();
    })
    .catch((error) => {
      toast("Some error occured sending the message", {type: "error"})
      console.error(error);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={spacing.mb8}>
      <Helmet>
          <title>{'Contact us - Solutions database'}</title>
          <meta name="description" content="Contact us Description"/>
          <meta property="og:title" content="Contact us"/>
          <meta property="og:description" content="Contact us Description"/>
          <meta property="og:image" content="https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg"/>
          <meta property="og:type" content="website"/>
      </Helmet>
      <Banner
        className={grid.edge}
        media={
          <div
            style={{
              opacity: 0.15,
              backgroundImage: `url(https://ik.imagekit.io/tcvka0ufln/home_TUhFZjeTJ.jpg)`,
              backgroundSize: "cover",
              backgroundPosition: "0 50%",
            }}
          />
        }
      >
       <H1 vol={8} uppercase>
          <span className={classnames(text.textBannerInline, text.textBg2)}>
            Contact Us
          </span>
        </H1>
        <SocialShare title={'Contact us'} url={document.URL} description={'Contact us Description'}></SocialShare>
      </Banner>
      <br />
      <Grid tag="main" variant={1} formatted>
        <div className={grid.mid} onSubmit={handleSubmit(onSubmit)}>
          <form className='contactUsForm'>
          <InputText
            placeholder="Name..."
            name="name"
            ref={register({ required: true})}
            label={`Name`}
            vol={3}
            variant={3}
            id={`sol1`}
          />
          {errors.name && <p>This field is required</p>}
          <br />
          <br />
          <InputText
            placeholder="Email..."
            name="email"
            ref={register({ 
              required: true,
              pattern: emailRegex,
            })}
            label={`Email`}
            vol={3}
            variant={3}
            id={`sol2`}
          />
          {errors.email?.type === "required" && <p>This field is required</p>}
          {errors.email?.type === "pattern" && <p>Please enter VALID email address</p>}
          <br />
          <br />
          <InputText
            label={`Message`}
            name="message"
            ref={register({ required: true})}
            placeholder="Start typing..."
            vol={3}
            variant={3}
            rows={10}
            id={`sol3`}
          />
          {errors.message && <p>This field is required</p>}
          <Button
            variant={3}
            vol={5}
            tone={5}
            className={spacing.mt2}
            fullWidth
            type="submit"
          >
            Send
          </Button>
          </form>
        </div>
      </Grid>
      <ToastContainer
      position="bottom-center"
      autoClose={4000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      />
    </div>
  );
};

export default ContactUs;
